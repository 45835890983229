import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ERROR } from 'utils/constants';
import Error from './error';
import Loader from './loader';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/:recordId"
          element={<Loader />}
        />
        <Route
          path="*"
          element={<Error code={ERROR.NOT_FOUND} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
