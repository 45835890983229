import React, { useEffect } from 'react';
import storage from 'utils/data/storage';
import { getSearchParam } from 'utils/params';
import player from 'utils/player';
import { WEBHOOK_URL, WEBHOOK_INTERVAL_MINUTES } from 'utils/constants';
import logger from 'utils/logger';


const WebhookUpdater = () => {
  
  const formatTime = (seconds) => {
    if (!seconds) {
        return null;
    }
    const totalSeconds = Math.floor(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    return `${hours}h${minutes}m${remainingSeconds}s`;
  }

  let stopSending = false;

  const sendPayloadToWebhook = () => {

    const max = player.primary?.duration();
    const currentTime = player.primary?.currentTime();
    const paused = player.primary?.paused();

    const payload = {
      currentTime: formatTime(currentTime),
      userId: getSearchParam('user'),
      recordId: storage.metadata.id,
    };

    if (!paused) {
      stopSending = false;
    }

    // Check that we have all parameters
    if (WEBHOOK_URL && payload.currentTime && payload.userId && payload.recordId && !stopSending){
      console.log(WEBHOOK_URL, payload, max, currentTime, paused)
      // Don't send requests if video has ended and first request has been sent
      if (paused) {
        stopSending = true;
      }

      fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          logger.info('Payload sent successfully!');
        } else {
          logger.error('Error sending payload:', response.statusText);
        }
      }).catch((error) => {
        logger.error('Error sending payload:', error);
      });
    }
  };

  useEffect(() => {
    // Send the initial payload immediately
    sendPayloadToWebhook();


    // Schedule updates every 2 minutes
    const intervalId = setInterval(() => {
      sendPayloadToWebhook();
    }, WEBHOOK_INTERVAL_MINUTES * 2 * 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  });

  return <></>;
};

export default WebhookUpdater;
